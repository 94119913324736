import * as React from "react";
import Head from "next/head";
import { Box } from "@material-ui/core";
import dynamic from "next/dynamic";

const DynamicFullDialogWithNoSSR = dynamic(
  () => import('../FullDialog'),
  { ssr: false }
)

const DynamicCartWithNoSSR = dynamic(
  () => import('../Cart'),
  { ssr: false }
)

const DynamicNavigationBarWithNoSSR = dynamic(
  () => import('../NavigationBar'),
  { ssr: false }
)

const DynamicSearchDialogWithNoSSR = dynamic(
  () => import('../SearchDialog'),
  { ssr: false }
)

const DynamicUserMobileMenuWithNoSSR = dynamic(
  () => import('../../navigation/components/UserMobileMenu'),
  { ssr: false }
)

type Props = {
  title?: string;
  className?: string;
  marginTop?: boolean;
  showCart?: boolean;
};

export const Layout: React.FunctionComponent<Props> = ({
  children,
  title = "",
  className,
  marginTop = true,
  showCart = true,
}) => {
  const [openSearch, setOpenSearch] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);

    return (
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Head>
          <title>{title}</title>
          <meta charSet="utf-8" />
          <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      />
        </Head>
        <DynamicNavigationBarWithNoSSR openMenu={() => setOpenMenu(true)} openSearch={() => setOpenSearch(true)} />
        <DynamicFullDialogWithNoSSR open={openMenu} handleClose={() => setOpenMenu(false)}>
          <DynamicUserMobileMenuWithNoSSR onClose={() => setOpenMenu(false)} />
        </DynamicFullDialogWithNoSSR>
        <DynamicSearchDialogWithNoSSR open={openSearch} handleClose={() => setOpenSearch(false)} />
        <Box
          flexGrow={1}
          mt={marginTop ? 2 : 0}
        >
          {children}
        </Box>
        <DynamicCartWithNoSSR />
      </Box>
    )
};
