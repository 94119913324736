import {
  Box,
  Button,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { GroceriesHorizontalScroll } from "@mealhaus/components/GroceriesHorizontalScroll";
import { RecipesHorizontalScroll } from "@mealhaus/components/RecipesHorizontalScroll";
import { routes } from "@mealhaus/navigation";
import { getCategories } from "@mealhaus/product-categories/service";
import { getHomeRecipes } from "@mealhaus/recipes";
import { GetStaticProps } from "next";
import Link from "next/link";
import React from "react";
import { Layout } from "@mealhaus/components/layouts/Layout";
import { useGetCategories, useGetTopSellers } from "@mealhaus/hooks";
import { InfiniteScroll } from "@mealhaus/components/InfiniteScroll";
import { dehydrate, Hydrate, QueryClient } from "react-query";
import { buildQueryParams } from "@mealhaus/utils/build-query-params.util";

export const getStaticProps: GetStaticProps = async (context) => {
  const [recipes] = await Promise.all([getHomeRecipes()])
  const queryClient = new QueryClient();
  await queryClient.prefetchInfiniteQuery(['categories'],
    async ({ pageParam = 1 }) => {
      const query = buildQueryParams({ page: pageParam, limit: 3 });
      const dto = await getCategories(query);
      return { ...dto, items: dto.items };
    },
    {
      getNextPageParam: (lastPage, ...r) => {
        return !lastPage.last ? lastPage.page + 1 : undefined;
      },
    });

  return {
    props: {
      recipes,
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient)))
    },
    revalidate: 86400
  }
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btn: {
      textTransform: "none",
      fontWeight: `normal`,
    },
    titleBar: {
      margin: theme.spacing(1, 0),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      fontWeight: 600,
      textTransform: 'capitalize'
    },
    row: {
      marginBottom: theme.spacing(2),
    },
  })
);

const _Home = React.memo(({ recipes }: any) => {
  const classes = useStyles();
  const { data: categories, hasNextPage, isFetching, fetchNextPage } = useGetCategories();
  const { data: topSellers } = useGetTopSellers();
  const mappedCategories = categories.pages.map((page) => page.items).reduce((acc, items) => [...acc, ...items], [])

  return (
    <Layout title="Groceries delivered in 30 minutes | Mealhaus">
      <Container maxWidth='lg'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.titleBar}>
              <Typography className={classes.title}>Top Sellers</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <GroceriesHorizontalScroll
              groceries={topSellers}
              isLoading={false}
            />
          </Grid>
        </Grid>
        <Box style={{ overflow: 'visible' }}>
          <InfiniteScroll canFetchMore={hasNextPage} isFetching={isFetching} fetchMore={fetchNextPage}>
            {
              mappedCategories.map((category) => {
                return (
                  <Grid container spacing={2} key={category.id}>
                    <Grid item xs={12}>
                      <div className={classes.titleBar}>
                        <Typography className={classes.title}>
                          {category.name}
                        </Typography>
                        <Link
                          href={`${routes.CATEGORIES}/${category.id}`}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            color="default"
                            disableElevation
                            className={classes.btn}
                          >
                            See all
                          </Button>
                        </Link>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <GroceriesHorizontalScroll
                        groceries={category.addOns.slice(0, 5)}
                        isLoading={false}
                      />
                    </Grid>
                  </Grid>
                );
              })}
          </InfiniteScroll>
        </Box>
      </Container>
    </Layout>
  )
})
export const Home = ({ recipes, dehydratedState }) => {
  return (
    <Hydrate state={dehydratedState}>
      <_Home recipes={recipes} />
    </Hydrate>
  );
};

export default Home;
