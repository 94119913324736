import { Box } from '@material-ui/core';
import React from 'react';
import Loader from './Loader';

interface Props {
    canFetchMore?: boolean;
    isFetching: boolean;
    fetchMore: () => void;
    children: any;
}

export const useIntersectionObserver = ({
    target,
    enabled,
    onIntersect,
    root,
    rootMargin,
    threshold,
}: IntersectionObserverInit & {
    target: React.MutableRefObject<Element | null>;
    enabled: boolean;
    onIntersect: () => void;
}) => {
    React.useEffect(() => {
        if (!target.current || !enabled) return;

        const intersectionObserver = new IntersectionObserver(([entry]) => entry.isIntersecting && onIntersect(), { root, rootMargin, threshold });

        const elem = target.current;
        intersectionObserver.observe(elem);

        return () => {
            intersectionObserver.unobserve(elem);
        };
    }, [target, enabled, onIntersect, root, rootMargin, threshold]);
};

export const InfiniteScroll = ({ canFetchMore, isFetching, fetchMore, children }: Props) => {
    const infiniteScrollAnchor = React.useRef<HTMLDivElement>(null);

    useIntersectionObserver({
        target: infiniteScrollAnchor,
        enabled: !!canFetchMore,
        onIntersect: fetchMore,
        rootMargin: '250px',
    });

    return (
        <>
            {children}

            {isFetching && <Loader />}

            {canFetchMore === false && <Box textAlign="center"></Box>}

            <div ref={infiniteScrollAnchor} />
        </>
    );
};
