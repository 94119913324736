import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';

const variants = ['item0', 'item1', 'item2', 'item3']

export default function Loader() {
    return (
        <Grid container spacing={8}>
            <Grid item xs>
                {variants.map((variant) => (
                    <Skeleton key={variant} />
                ))}
            </Grid>
        </Grid>
    );
}
