import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import { AddOn, AddOnCardWithButton } from "@mealhaus/add-ons";
import Loader from "./Loader";
import { routes } from "@mealhaus/navigation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      backgroundColor: "transparent",
      overflow: "hidden",
    },
    imageList: {
      flexWrap: "nowrap",
      width: "100%",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
      scrollbarWidth: `none`,
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  })
);

export const GroceriesHorizontalScroll = ({ groceries, isLoading, redirect = '' }: { groceries: AddOn[]; isLoading: boolean, redirect?: string }) => {
  const classes = useStyles();

  if (!groceries) {
    return <Loader />;
  }

  return (
    <div className={classes.root}>
      <ImageList
        className={classes.imageList}
        cols={2.5}
        rowHeight={300}
      >
        {groceries.map((grocery) => (
          <ImageListItem key={grocery.id}>
            <AddOnCardWithButton addOn={grocery} url={`${routes.GROCERIES}/${grocery.id}${!!redirect ? `?redirect=${redirect}` : ''}`} />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};
